import React from "react";
// import { Routes, Route } from 'react-router-dom';

function App() {
  return {
    /* <Routes>
 <Route path="/" element={<ViewLawyers/>} />
 <Route path="/view-lawyer/:id" element={<ViewLawyer/>} />
 <Route path="/edit-lawyer/:id" element={<EditLawyer/>} />
 <Route path="/add-huissier" element={<AddHuissier/>} />
 <Route path="/add-notaire" element={<AddNotaire/>} />
 </Routes> */
  };
}

export default App;
