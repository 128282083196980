import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
              description: {
                searchbar: 'Search...',
                tabName: 'Name',
                tabPhone: 'Phone Number',
                view: 'view',
                edit: 'edit',
                modalFile:'Choose a file',
                modalSubmit: 'Submit the image',
                statut: 'Lawyer',
                inscrip: 'Register date',
                email: 'No email'
              }
            }
          },
          fr: {
            translation: {
              description: {
                searchbar: 'Recherche...',
                tabName: 'Nom',
                tabPhone: 'Téléphone',
                view: 'Voir',
                edit: 'Modifier',
                modalFile:'Choisir un fichier',
                modalSubmit: 'Ajouter l\'image',
                statut: 'Avocat',
                inscrip: 'Date d\'inscription',
                email: 'Pas d\'email'
              }
            }
          }
    }
  });

export default i18n;